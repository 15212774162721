/* purgecss start ignore */
@tailwind base;
@tailwind components;

@font-face {
    src: url('./SnvCondD-RegFixed.ttf');
    font-family: "Snv";
}

@font-face {
    src: url('./Gotham-Book.ttf');
    font-family: "Gotham";
}

* { position: relative; }

html {
    font-size: 20px;
    line-height: 1.3;
}
@screen lg {
    html {
        font-size: 24px;
    }
}

button, button:focus { outline: 0 }

.bg-burger {
    background-image: url('./burger.svg');
}

.kirbytext p:not(:last-child) {
    @apply mb-2lh;
}

.mb-snv-tight { margin-bottom: -.35em}
.my-snv-ultratight { margin-top: .11em; margin-bottom: -.24em;}

.kirbytext h1,
.kirbytext h2, 
.kirbytext h3, 
.kirbytext h4, 
.kirbytext h5, 
.kirbytext h6 {
    @apply font-snv;
    @apply leading-none;
    @apply text-2xl;
    @apply mb-snv-tight;
    @apply pb-1;
}

.svg-inline--fa {
    height: 1em;
    display: inline-block;
}

/* purgecss end ignore */

@tailwind utilities;